import React, {Component} from 'react';
import "./nav.css";
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai';
import {BiBook, BiMessageRoundedDetail} from 'react-icons/bi';
import {GrServices} from 'react-icons/gr';
import {useState} from 'react';

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')

    return (
        <nav>
            <a href="#"
               onClick={() => setActiveNav('#')}
               className={activeNav === '#' ? 'active' : ''}> <AiOutlineHome/> </a>
            <a href="#about"
               onClick={() => setActiveNav('#about')}
               className={activeNav === '#about' ? 'active' : ''}> <AiOutlineUser/> </a>
            <a href="#experience"
               onClick={() => setActiveNav('#experience')}
               className={activeNav === '#experience' ? 'active' : ''}> <BiBook/> </a>
            {/*social__stroke_primary*/}
            <a href="#services"
               onClick={() => setActiveNav('#services')}
               className={activeNav === '#services' ? 'active social__stroke_primary' : 'social__stroke_primary'}> <GrServices/> </a>
            <a href="#contact"
               onClick={() => setActiveNav('#contact')}
               className={activeNav === '#contact' ? 'active' : ''}> <BiMessageRoundedDetail/> </a>
        </nav>
    );
}

export default Nav;